exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assistance-js": () => import("./../../../src/pages/assistance.js" /* webpackChunkName: "component---src-pages-assistance-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contractor-js": () => import("./../../../src/pages/contractor.js" /* webpackChunkName: "component---src-pages-contractor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rental-js": () => import("./../../../src/pages/rental.js" /* webpackChunkName: "component---src-pages-rental-js" */),
  "component---src-pages-spa-js": () => import("./../../../src/pages/spa.js" /* webpackChunkName: "component---src-pages-spa-js" */),
  "component---src-pages-transport-js": () => import("./../../../src/pages/transport.js" /* webpackChunkName: "component---src-pages-transport-js" */),
  "component---src-pages-truck-auto-js": () => import("./../../../src/pages/truck-auto.js" /* webpackChunkName: "component---src-pages-truck-auto-js" */)
}

